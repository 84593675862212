.filter-parent {
  padding: 0;
  margin: 10px;
}

.filter-row-1 {
  justify-content: space-around;
}

.filter-row-2 {
  justify-content: center;
}

.filter-row-1,
.filter-row-2 {
  width: 100vw;
}

.clear-date {
  position: absolute;
  bottom: 0;
}

.candidate-pagination {
  justify-content: center !important;
}
